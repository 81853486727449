import Link from "next/link";

export default function HttpStatus404() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">ページが見つかりません</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            お探しのページは見つかりませんでした。URLが正しいかご確認ください。
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href="/"
              className="hover:primaryHover rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              ホームへ
            </Link>
            <Link href="/inquiry" className="text-sm font-semibold text-gray-900">
              お問い合わせ <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
